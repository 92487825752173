import axios from "axios";
import { getCookie } from "../../Pages/Login/Login";

export const Url = "https://sales-system.tryasp.net/api/";

const getToken = () => {
  const userCookie = getCookie("user");
  return userCookie ? JSON.parse(userCookie).token : null;
};

export async function fetchDataWithRetries(URL, setApiData, status) {
  console.log(`${Url}${URL}`)
  try {
    console.log(`${Url}${URL}`);
    const response = await axios.get(`${Url}${URL}`, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    });
    setApiData(response.data);
    console.log("from api files" + response.data);
    status(response.status);
    return response.data;
  } catch (error) {
    console.log("error");
    console.log(error);
  }
}

export async function fetch(URL) {
  try {
    console.log(`${Url}${URL}`);
    const response = await axios.get(`${Url}${URL}`, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    });
    return response.data;
  } catch (error) {
    console.log(error);
  }
}
export async function postData(APiURL, userData, setError) {
  try {
    const response = await axios.post(`${Url}${APiURL}`, userData, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getToken()}`,
      },
    });
    //
    return response;
  } catch (error) {
    console.error(error);
    if (error.response && error.response.status === 401) {
      setError("كلمة السر أو البريد الإلكتروني غير صحيح");
    } else if (error.response && error.response.status === 405) {
      setError("الطلب غير مسموح.");
    } else {
      setError("حدث خطأ ما. حاول مرة أخرى لاحقاً.");
    }
    throw error;
  }
}

export async function postDatatoQueryParams(APiURL, params) {
  try {
    const queryParams = new URLSearchParams(params).toString();
    const fullUrl = `${Url}${APiURL}?${queryParams}`;
    const response = await axios.post(fullUrl, null, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getToken()}`,
      },
    });
    return response;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export async function putDataToQueryParams(APiURL, params) {
  try {
    const queryParams = new URLSearchParams(params).toString();
    const fullUrl = `${Url}${APiURL}?${queryParams}`;
    const response = await axios.put(fullUrl, null, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getToken()}`,
      },
    });
    return response;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export async function putData(APiURL, data) {
  try {
    console.log(getToken());
    const response = await axios.put(`${Url}${APiURL}`, data, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getToken()}`,
      },
    });
    return response;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export async function putFormData(APiURL, data) {
  try {
    const formData = new FormData();

    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        formData.append(key, data[key]);
      }
    }
    const response = await axios.put(`${Url}${APiURL}`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${getToken()}`,
      },
    });

    return response;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export async function deleteFunction(APiURL) {
  try {
    const response = await axios.delete(`${Url}${APiURL}`, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
        "X-Request-With": "XMLHttpRequest",
      },
    });
    return response;
  } catch (error) {
    if (error.response && error.response.status === 404) {
      console.log(`Resource not found: ${APiURL}`);
    } else {
      console.error(`Error deleting resource: ${APiURL}`, error);
    }
  }
}
