import { useCallback } from "react";

const usePrintInvoicesSingle = () => {
  const printRepInvoicesSingle = useCallback((data) => {
    try {
      const rows = data
        .map(
          (item) => `
    <div class="page-break">
     <div class="row">
        <div class="client-info">
                  <p><strong>الكود </strong> ${item.invoiceCode || "N/A"}</p>

          <p><strong>اسم العميل:</strong> ${item.customer || "N/A"}</p>
          <p><strong>العنوان:</strong> ${item.address || "N/A"}</p>
          <p><strong>الهاتف:</strong> ${item.phone || "N/A"}</p>
          <p><strong>المحافظه:</strong> ${
            item.representative?.governorateName || "N/A"
          }</p>
        </div>
        <div class="client-info">
          <p><strong>تاريخ الفاتورة:</strong> ${
            new Date(item.invoiceDate).toLocaleDateString("ar-EG") || "N/A"
          }</p>
          <p><strong>السكرتير:</strong> ${item.userName || "N/A"}</p>
          <p><strong>الهاتف:</strong> ${item.phone || "N/A"}</p>
          <p><strong>المندوب:</strong> ${
            item.representative?.fullName || "N/A"
          }</p>
        </div>
      </div>

    <table>
        <thead>
          <tr>
            <th> الصنف</th>
        
        
            <th>الكمية</th>
                <th>السعر</th>
            <th>الإجمالي</th>
      
          </tr>
        </thead>
        <tbody>
          ${(item.invoiceCategories || [])
            .map(
              (category) => `
              <tr>
                <td>${category.category?.name || "N/A"}</td>
          
                <td>${category.quantity || 0}</td>
                      <td>${category.price || 0}</td>
                <td>${category.total || 0}</td>
          
              </tr>
              `
            )
            .join("")}
        </tbody>
      </table>
    


    


  


    <table style="width: 40%; margin: 0 auto; border-collapse: collapse; border: 1px solid #ddd; text-align: right; background-color: #f9f9f9;">
  <thead>

  </thead>
  <tbody>
    <tr style="background-color: #f2f2f2;">
      <td style="padding: 10px; border: 1px solid #000; font-size: 16px; color: #333;">الاجمالي</td>
      <td style="padding: 10px; border: 1px solid #000; font-size: 16px; color: #333;">${
        item.totalPrice  - item.shipping
      }</td>
    </tr>
    <tr>
      <td style="padding: 10px; border: 1px solid #000; font-size: 16px; color: #333;">الشحن</td>
      <td style="padding: 10px; border: 1px solid #000; font-size: 16px; color: #333;">${
        item.shipping || 0
      }</td>
    </tr>
    <tr style="background-color: #f2f2f2;">
      <td style="padding: 10px; border: 1px solid #000; font-size: 16px; color: #333;">الاجمالي مع الشحن</td>
      <td style="padding: 10px; border: 1px solid #000; font-size: 16px; color: #333;">${
        item.totalPrice + item.shipping
      }</td>
    </tr>
  </tbody>
</table>

    </div>
    `
        )
        .join("");

      const invoicesPage = `  <html>
          <head>
            <title>فواتير</title>
  
          </head> 
          <style>
          
            body {
          font-family: "Arial", sans-serif;
          margin: 0 auto;
          padding: 20px;
          direction: rtl;
          color: #333;
          background-color: #f9f9f9;
        }


        .page-break {
          page-break-after: always;
          padding: 20px; 
        
          margin-top: 50px;
      
          width: 70%;  
                   border: 1px solid #000;
          margin-bottom: 50px;
          background-color: #fff;
          border-radius: 8px;
        } 
          

        .invoice-header h2 {
          margin: 0;
          text-align: center;
          color: #2c3e50;
        }

      .row {
  display: flex;
  justify-content: center;

  align-items: flex-start;
  gap: 20px; /* Adjust gap between columns */
  margin-bottom: 20px; /* Space between rows */
}

.client-info {
  flex: 

  border-radius: 4px;
  padding: 15px;


  color: black; /* Text color black */
  font-size: 18px; /* Larger font size */
  font-weight: 500; /* Slightly thicker text */
}

.client-info p {
  margin: 5px 0;
}


        table {
          width: 100%;
          border-collapse: collapse;
          margin: 20px 0;
          font-size: 14px;
          background: #fff;
        }

        th, td {
          border: 1px solid black !important;
          padding: 8px; 
font-size: 16px;
font-weight: 800;
          text-align: center;
        }

        th {
    
          color: black;
        }

        tr:nth-child(even) {
          background-color: #f9f9f9;
        }

        .summary-table {
          margin-top: 10px;
          border: none;
          text-align: left;
        }

        .summary-table td {
          padding: 5px;
        } 

              .container {
                text-align: center;
                max-width: 1200px;
                margin: 0 auto;
                padding: 20px;
                background-color: #fff;
                border-radius: 8px;
                box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
              }
          </style>
          <body>
  <!-- First Section: Rows -->
  <div style="min-height: 100vh; display: flex; justify-content: center; align-items: center; box-sizing: border-box; flex-direction: column;">
    ${rows}
  </div>





</body>


        </html>
      `;

      const invoicesTab = window.open("", "invoicesPage");
      invoicesTab.document.write(invoicesPage);
      invoicesTab.document.close();
      invoicesTab.print();
    } catch (error) {
      console.error("Error printing invoices:", error);
    }
  }, []);

  return printRepInvoicesSingle;
};

export default usePrintInvoicesSingle;
