import React, { useState, useEffect, useMemo, useRef } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Button,
  Checkbox,
  Paper,
  CircularProgress,
  Modal,
  Box,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPrint, faSave } from "@fortawesome/free-solid-svg-icons";
import usePrintInvoicesSingle from "../../../hooks/UsePrintInvoices";
import Pagination from "@mui/material/Pagination";
import "jspdf-autotable";
import {
  fetchDataWithRetries,
  putData,
  fetch,
} from "../../../Component/function/FunctionApi";
import * as XLSX from "xlsx";
import "./TableBills.css";
import Swal from "sweetalert2";
import usePrintInvoices from "../../../hooks/UsePrint";

// Custom hook for debouncing input values
const useDebounce = (value, delay) => {
  const [debouncedValue, setDebouncedValue] = useState(value);
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);
    return () => clearTimeout(handler);
  }, [value, delay]);
  return debouncedValue;
};

const DataTable = () => {
  const printRepInvoices = usePrintInvoices();

  const [rows, setRows] = useState({ data: [] });
  const [itemsToShow, setItemsToShow] = useState(30);
  const printRepInvoicesSingle = usePrintInvoicesSingle();
  console.log(rows);
  const [searchTerms, setSearchTerms] = useState({});
  const [selectedRows, setSelectedRows] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState("");
  const debouncedSearchTerms = useDebounce(searchTerms, 300);
  const [statusMessage, setStatusMessage] = useState(null);
  const [openRepModal, setOpenRepModal] = useState(false);
  const [representatives, setRepresentatives] = useState([]);
  const [selectedRepresentative, setSelectedRepresentative] = useState("");
  const [selectedDate, setSelectedDate] = useState(() => {
    const today = new Date();
    return today.toISOString().split("T")[0];
  });
  const invoiceCodeRef = useRef();
  const invoiceDateRef = useRef();
  const customerRef = useRef();
  const addressRef = useRef();
  const governorateRef = useRef();
  const phoneRef = useRef();
  const invoiceCategoriesRef = useRef();
  const dateCreatedRef = useRef();
  const userNameRef = useRef();
  const statusRef = useRef();
  const totalPriceRef = useRef();
  const [status, setApiStatus] = useState(null);
  const [statusMessageModalOpen, setStatusMessageModalOpen] = useState(false);
  const [showPrintModel, setShowPrintModel] = useState(false);

  const handelPrintDaily = async () => {
    console.log("start print");

    console.log(selectedRepresentative, selectedDate);

    try {
      const url = `Represntative/get-dailyDateOfRepresntative?represntativeId=${selectedRepresentative}&dateDaily=${selectedDate}`;
      console.log(url);
      const response = await fetch(url);
      const data = await response.data;
      console.log(response);
      if (data[0].invoices.length === 0) {
        console.log("error");
        setShowPrintModel(false);
        Swal.fire({
          icon: "info",
          title: "لا يوجد  فواتير",
          text: "لا يوجد فواتير لهذا المندوب  في هذه اليوم",
        });
      } else {
        console.log(data);
        printRepInvoices(data);
      }
    } catch (error) {
      Swal.fire({
        icon: "خطا",
        title: "Error",
        text: "حدث خطاء في جلب البيانات اليومية",
      });
      console.error("Error fetching daily data:", error);
    }
  };

  const fetchInvoices = async () => {
    setLoading(true);
    try {
      await fetchDataWithRetries(
        "Invoice/GetInvoicesWithNoRepresentative",
        setRows,
        setApiStatus
      );

      await fetchDataWithRetries(
        "Represntative/get-Represntatives",
        setRepresentatives,
        setApiStatus
      );
    } catch (error) {
      console.log("error", error);
      if (error.response && error.response.status === 403) {
        setError("ليس لديك صلاحية للوصول إلى إدارة الفواتير");
      } else {
        setError("Error fetching data after multiple retries");
      }
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchInvoices();
  }, []);

  const handleSearch = (column, value) => {
    setSearchTerms((prev) => ({ ...prev, [column]: value }));
  };

  const filteredRows = useMemo(() => {
    if (!rows.data) return [];
    return rows.data.filter((row) => {
      return Object.keys(debouncedSearchTerms).every((column) => {
        const searchTerm =
          debouncedSearchTerms[column]?.toString().toLowerCase() || "";
        if (column === "governorate") {
          return row.governorate?.name?.toLowerCase().includes(searchTerm);
        }
        if (column === "itemsCount") {
          return row.itemsCount?.toString().includes(searchTerm);
        }
        return row[column]?.toString().toLowerCase().includes(searchTerm);
      });
    });
  }, [rows, debouncedSearchTerms]);

  const handleItemsToShowChange = (event) => {
    setItemsToShow(event.target.value);
  };

  const displayedItems = filteredRows.slice(0, itemsToShow);

  const handleSelectAll = (event) => {
    setSelectedRows(
      event.target.checked ? filteredRows.map((row) => row.id) : []
    );
  };

  const handleOpenRepModal = () => {
    setOpenRepModal(true);
  };
  const handleRowSelect = (event, row) => {
    setSelectedRows((prev) =>
      event.target.checked
        ? [...prev, row.id]
        : prev.filter((id) => id !== row.id)
    );
    console.log(selectedRows);
  };

  const handleExportToExcel = () => {
    const ws = XLSX.utils.json_to_sheet(filteredRows);
    const wb = XLSX.utils.book_new();
    console.log(ws);
    XLSX.utils.book_append_sheet(wb, ws, "فواتير");
    XLSX.writeFile(wb, "فواتير.xlsx");
  };

  const handlePrint = () => {
    if (selectedRows.length === 0) {
      alert("لا يوجد بيانات للطباعة");
      return;
    }
    let data = [];
    filteredRows.forEach((row) => {
      if (selectedRows.includes(row.id)) {
        data.push(row);
      }
    });
    console.log(data);
    printRepInvoicesSingle(data);
  };
  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedStatus("");
    setStatusMessage(null);
  };

  const handleStatusChange = (event) => {
    setSelectedStatus(event.target.value);
  };

  const handleUpdateStatus = async () => {
    try {
      await putData("Invoice/update-invoice-status", {
        InvoiceIds: selectedRows,
        InvoiceStatus: selectedStatus,
      });
      setStatusMessage("تم تعديل حالة الفاتورة بنجاح");
      await fetchDataWithRetries(
        "Invoice/GetInvoicesWithNoRepresentative",
        setRows
      );
    } catch (error) {
      setStatusMessage("حدث خطأ أثناء تعديل حالة الفاتورة");
      console.error("Error updating status:", error);
    } finally {
      setStatusMessageModalOpen(true);
      handleCloseModal();
    }
  };

  const handleUpdateRepresentative = async () => {
    try {
      const requestBody = {
        invoiceIds: selectedRows,
        newRepresentativeId: selectedRepresentative,
        dateDaily: new Date(selectedDate).toISOString(),
      };
      console.log(requestBody);
      await putData("Invoice/update-representative-invoices", requestBody);
      setStatusMessage("تم تعديل المندوب بنجاح");
      window.location.reload();
    } catch (error) {
      console.error("Error updating representative:", error);
      setStatusMessage("حدث خطأ أثناء تعديل المندوب");
    } finally {
      setStatusMessageModalOpen(true);
      setOpenRepModal(false);
    }
  };

  if (loading) return <CircularProgress />;
  if (error) {
    return (
      <div style={{ textAlign: "center", marginTop: "20px" }}>
        <Typography variant="h6" color="error">
          {error}
        </Typography>
        <img
          src="/403.png"
          alt="403 Forbidden"
          style={{ maxWidth: "100%", height: "auto" }}
        />
      </div>
    );
  }
  const handleClearSearch = () => {
    // Clear the values using refs
    invoiceCodeRef.current.value = "";
    invoiceDateRef.current.value = "";
    customerRef.current.value = "";
    addressRef.current.value = "";
    governorateRef.current.value = "";
    phoneRef.current.value = "";
    invoiceCategoriesRef.current.value = "";
    dateCreatedRef.current.value = "";
    userNameRef.current.value = "";
    statusRef.current.value = "";
    totalPriceRef.current.value = "";

    setSearchTerms({
      invoiceCode: "",
      invoiceDate: "",
      customer: "",
      address: "",
      governorate: "",
      phone: "",
      invoiceCategories: "",
      dateCreated: "",
      userName: "",
      status: "",
      totalPrice: "",
    });
  };
  const renderSearchInput = (column, placeholder, ref) => (
    <TableCell key={column}>
      <TextField
        variant="outlined"
        size="small"
        type={`${
          column == "dateCreated" || column == "invoiceDate" ? "date" : "text"
        }`}
        placeholder={placeholder}
        value={searchTerms[column] || ""}
        onChange={(e) => handleSearch(column, e.target.value)}
        inputRef={ref} // Attach ref to the input
      />
    </TableCell>
  );
  console.log(rows);

  return (
    <div className=" font-medium">
      <Paper>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: "0 16px",
          }}
        >
          <div className="flex  items-center gap-10">
            <Button onClick={handleExportToExcel}>
              <FontAwesomeIcon icon={faSave} /> تصدير إلى Excel
            </Button>
            <button
              className="px-4 my-3 py-2 bg-red-500 text-white font-semibold rounded-lg hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-opacity-50"
              onClick={handleClearSearch}
            >
              مسح
            </button>
          </div>

          <div className="flex  items-center gap-10">
            <select
              value={itemsToShow}
              onChange={handleItemsToShowChange}
              className=" p-1 md:p-2 lg:px-4 text-center my-3 lg:py-2 bg-blue-500 text-white font-normal rounded-lg"
            >
              <option value={10}>10</option>
              <option value={50}>50</option>

              <option value={100}>100</option>
              <option value={200}>200</option>
              <option value={300}>300</option>
              <option value={400}>400</option>
              <option value={400}>400</option>
              <option value={500}>500</option>
              <option value={1000}>+1000</option>
              <option value={filteredRows.length}>All</option>
            </select>
            <Button onClick={() => handlePrint()}>
              <FontAwesomeIcon icon={faPrint} /> طباعة
            </Button>
          </div>
        </div>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox" />
                {rows.data && rows.data.length > 0 && (
                  <>
                    {renderSearchInput("invoiceCode", "كود", invoiceCodeRef)}
                    {renderSearchInput(
                      "invoiceDate",
                      "التاريخ",
                      invoiceDateRef
                    )}
                    {renderSearchInput("customer", "العميل", customerRef)}
                    {renderSearchInput("address", "العنوان", addressRef)}
                    {renderSearchInput(
                      "governorate",
                      "المحافظة",
                      governorateRef
                    )}
                    {renderSearchInput("phone", "الهاتف", phoneRef)}
                    {renderSearchInput(
                      "invoiceCategories",
                      "الفئات",
                      invoiceCategoriesRef
                    )}
                    {renderSearchInput(
                      "dateCreated",
                      "التاريخ اليومي",
                      dateCreatedRef
                    )}
                    {renderSearchInput("userName", "المندوب", userNameRef)}
                    {renderSearchInput("status", "الحالة", statusRef)}
                    {renderSearchInput("totalPrice", "الإجمالي", totalPriceRef)}
                  </>
                )}
              </TableRow>
              <TableRow>
                <TableCell padding="checkbox">
                  <Checkbox
                    checked={selectedRows.length === filteredRows.length}
                    onChange={handleSelectAll}
                  />
                </TableCell>
                <TableCell className="table-header">كود</TableCell>
                <TableCell className="table-header">التاريخ</TableCell>
                <TableCell className="table-header">العميل</TableCell>
                <TableCell className="table-header">العنوان</TableCell>
                <TableCell className="table-header">المحافظة</TableCell>
                <TableCell className="table-header">الهاتف</TableCell>
                <TableCell className="table-header">الفئات</TableCell>
                <TableCell className="table-header">التاريخ اليومي</TableCell>
                <TableCell className="table-header">المندوب</TableCell>
                <TableCell className="table-header">الحالة</TableCell>
                <TableCell className="table-header">الإجمالي</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {displayedItems.length > 0 ? (
                displayedItems.map((row) => (
                  <TableRow
                    key={row.id}
                    className={
                      selectedRows.includes(row.id) ? "selected-row" : ""
                    }
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={selectedRows.includes(row.id)}
                        onChange={(event) => handleRowSelect(event, row)}
                      />
                    </TableCell>
                    <TableCell>{row.invoiceCode}</TableCell>
                    <TableCell>{row.invoiceDate.split("T")[0]}</TableCell>
                    <TableCell>{row.customer}</TableCell>
                    <TableCell>{row.address}</TableCell>
                    <TableCell>{row.governorate?.name}</TableCell>
                    <TableCell>{row.phone}</TableCell>
                    <TableCell>
                      {row.invoiceCategories?.map((cat) => cat?.category?.name || "صنف 1")}
                    </TableCell>
                    <TableCell>{row.dateCreated.split("T")[0]}</TableCell>
                    <TableCell style={{ alignItems: "center" }}>
                      {row.representative?.fullName ?? "_"}
                    </TableCell>

                    <TableCell>{row.status}</TableCell>
                    <TableCell>{row.totalPrice}</TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={12} style={{ textAlign: "center" }}>
                    No data available
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      <div className="div-open-modal">
        <div className="flex justify-center items-center">
          <div className="all-button">
            {selectedRows.length > 0 && (
              <div>
                <button className="open-modal-button" onClick={handleOpenModal}>
                  تعديل حالة الفاتورة
                </button>
              </div>
            )}
            <div>
              <button
                className="open-modal-button"
                onClick={() => setShowPrintModel(true)}
              >
                طباعه يوميه مندوب
              </button>
            </div>
            <div>
              {selectedRows.length > 0 && (
                <button
                  className="open-modal-button"
                  onClick={handleOpenRepModal}
                >
                  تعديل المندوب
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
      <Modal open={openModal} onClose={handleCloseModal} dir="rtl">
        <Box sx={{ padding: 4, backgroundColor: "white", borderRadius: 2 }}>
          <Typography variant="h6">تحديث حالة الفواتير</Typography>
          <Select
            value={selectedStatus}
            onChange={handleStatusChange}
            displayEmpty
            fullWidth
            variant="outlined"
            sx={{ marginBottom: 2 }}
          >
            <MenuItem value="" disabled>
              اختر الحالة الجديدة
            </MenuItem>
            <MenuItem value={1}>جديد</MenuItem>
            <MenuItem value={2}>تحت التجهيز</MenuItem>
            <MenuItem value={3}>تم التسليم</MenuItem>
            <MenuItem value={4}>مرتجع</MenuItem>
            <MenuItem value={5}>ملغي</MenuItem>
          </Select>
          <Button
            variant="contained"
            color="primary"
            onClick={handleUpdateStatus}
          >
            تأكيد
          </Button>
          <Button
            variant="outlined"
            onClick={handleCloseModal}
            style={{ marginRight: 8 }}
          >
            إلغاء
          </Button>
        </Box>
      </Modal>
      {openRepModal && (
        <Modal
          open={openRepModal}
          onClose={() => {
            window.location.reload();
            setOpenRepModal(false);
          }}
          dir="rtl"
        >
          <Box sx={{ padding: 4, backgroundColor: "white", borderRadius: 2 }}>
            <Typography variant="h6">تحديث المندوب</Typography>
            <Select
              value={selectedRepresentative}
              onChange={(e) => setSelectedRepresentative(e.target.value)}
              displayEmpty
              fullWidth
              variant="outlined"
              sx={{ marginBottom: 2 }}
            >
              <MenuItem value="" disabled>
                اختر المندوب
              </MenuItem>
              {representatives?.data.map((rep) => (
                <MenuItem key={rep.id} value={rep.id}>
                  {rep.fullName}
                </MenuItem>
              ))}
            </Select>
            <TextField
              variant="outlined"
              label="التاريخ"
              type="date"
              fullWidth
              value={selectedDate}
              onChange={(e) => setSelectedDate(e.target.value)}
              InputLabelProps={{ shrink: true }}
              sx={{ marginBottom: 2 }}
            />
            <Button
              variant="contained"
              color="primary"
              onClick={handleUpdateRepresentative}
            >
              تأكيد
            </Button>
            <Button
              variant="outlined"
              onClick={() => setOpenRepModal(false)}
              style={{ marginRight: 8 }}
            >
              إلغاء
            </Button>
          </Box>
        </Modal>
      )}
      {showPrintModel && (
        <Modal
          open={showPrintModel}
          onClose={() => setOpenRepModal(false)}
          dir="rtl"
        >
          <Box sx={{ padding: 4, backgroundColor: "white", borderRadius: 2 }}>
            <Typography variant="h6">طباعه يوميه المندوب</Typography>
            <Select
              value={selectedRepresentative}
              onChange={(e) => setSelectedRepresentative(e.target.value)}
              displayEmpty
              fullWidth
              variant="outlined"
              sx={{ marginBottom: 2 }}
            >
              <MenuItem value="" disabled>
                اختر المندوب
              </MenuItem>
              {representatives?.data.map((rep) => (
                <MenuItem key={rep.id} value={rep.id}>
                  {rep.fullName}
                </MenuItem>
              ))}
            </Select>
            <TextField
              variant="outlined"
              label="التاريخ"
              type="date"
              fullWidth
              value={selectedDate}
              onChange={(e) => setSelectedDate(e.target.value)}
              InputLabelProps={{ shrink: true }}
              sx={{ marginBottom: 2 }}
            />
            <Button
              variant="contained"
              color="primary"
              onClick={handelPrintDaily}
            >
              طباعه
            </Button>
            <Button
              variant="outlined"
              onClick={() => setShowPrintModel(false)}
              style={{ marginRight: 8 }}
            >
              إلغاء
            </Button>
          </Box>
        </Modal>
      )}
      <Modal
        open={statusMessageModalOpen}
        onClose={() => setStatusMessageModalOpen(false)}
        dir="rtl"
      >
        <Box sx={{ padding: 4, backgroundColor: "white", borderRadius: 2 }}>
          <Typography variant="h5" sx={{ padding: 2, borderRadius: 2 }}>
            {statusMessage}
          </Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={() => setStatusMessageModalOpen(false)}
          >
            إغلاق
          </Button>
        </Box>
      </Modal>
    </div>
  );
};

export default DataTable;
