import { useState, useEffect, useCallback } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faSave, faTrash } from "@fortawesome/free-solid-svg-icons";
import {
  fetchDataWithRetries,
  postDatatoQueryParams,
  putFormData,
  deleteFunction,
} from "../../../Component/function/FunctionApi";
import "./Delegate.css";

const API_ENDPOINTS = {
  FETCH: "Represntative/get-Represntatives",
  CREATE: "Represntative/create-represntative",
  UPDATE: "Represntative/id",
  DELETE: "Represntative/id",
  GOVERNORATES: "Governorate/get-governorates",
};

const MESSAGES = {
  FETCH_ERROR: "فشل في تحميل البيانات. يرجى المحاولة مرة أخرى لاحقًا.",
  UPDATE_SUCCESS: "تم تعديل المندوب بنجاح",
  CREATE_SUCCESS: "تم إضافة المندوب بنجاح",
  DELETE_SUCCESS: "تم حذف المندوب بنجاح",
  FORM_ERROR: "يرجى ملء جميع الحقول بشكل صحيح.",
};

function DelegateComponent() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [newDelegate, setNewDelegate] = useState({
    governorateId: "",
    firstName: "",
    fullName: "",
    phoneNumber: "",
  });
  const [isEditMode, setIsEditMode] = useState(false);
  const [apiData, setApiData] = useState([]);
  const [governorateOptions, setGovernorateOptions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState("");
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);
  const [saving, setSaving] = useState(false);

  useEffect(() => {
    fetchDelegates();
    fetchGovernorates();
  }, []);

  const fetchDelegates = useCallback(async () => {
    try {
      setLoading(true);
      setError(null);
      await fetchDataWithRetries(API_ENDPOINTS.FETCH, setApiData);
    } catch (err) {
      setError(MESSAGES.FETCH_ERROR);
    } finally {
      setLoading(false);
    }
  }, []);

  const fetchGovernorates = useCallback(async () => {
    try {
      await fetchDataWithRetries(
        API_ENDPOINTS.GOVERNORATES,
        setGovernorateOptions
      );
    } catch (err) {
      console.error("خطأ في جلب المحافظات:", err);
    }
  }, []);

  const handleOpenModal = (editMode = false, delegate = {}) => {
    setIsEditMode(editMode);
    setNewDelegate({
      governorateId: delegate.governorateId || "",
      fullName: delegate.fullName || "",
      phoneNumber: delegate.phoneNumber || "",
      id: delegate.id || null,
    });
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    resetForm();
  };

  const resetForm = () => {
    setNewDelegate({
      governorateId: "",
      firstName: "",
      fullName: "",
      email: "",
      phoneNumber: "",
    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewDelegate((prevData) => ({ ...prevData, [name]: value }));
  };

  const validateForm = () => {
    return Object.values(newDelegate).every((value) => value !== "");
  };

  const handleSave = async () => {
    // if (!validateForm()) {
    //   setError(MESSAGES.FORM_ERROR);
    //   return;
    // }

    setSaving(true); // Start loading
    const params = {
      id: newDelegate.id,
  
      FullName: newDelegate.fullName,
      PhoneNumber: newDelegate.phoneNumber,

      GovernorateId: newDelegate.governorateId,
    };

    try {
      if (isEditMode) {
        await putFormData(`${API_ENDPOINTS.UPDATE}`, params);
        setSuccessMessage(MESSAGES.UPDATE_SUCCESS);
      } else {
        await postDatatoQueryParams(API_ENDPOINTS.CREATE, params);
        setSuccessMessage(MESSAGES.CREATE_SUCCESS);
      }

      await fetchDelegates();
      setShowSuccessPopup(true);
      setTimeout(closeSuccessPopup, 3000);
    } catch (err) {
      console.log(err);
      setError(
        `خطأ: ${
          err.message || "فشل في حفظ البيانات. يرجى المحاولة مرة أخرى لاحقًا."
        }`
      );
    } finally {
      handleCloseModal();
      setSaving(false);
    }
  };

  const closeSuccessPopup = () => {
    setShowSuccessPopup(false);
  };

  const closeErrorPopup = () => {
    setError(false);
  };

  const handleDeleteConfirmation = (delegate) => {
    setItemToDelete(delegate.id);
    setIsDeleteModalOpen(true);
  };

  const handleDelete = async () => {
    try {
      await deleteFunction(`${API_ENDPOINTS.DELETE}?id=${itemToDelete}`);
      setSuccessMessage(MESSAGES.DELETE_SUCCESS);
      await fetchDelegates();
      setShowSuccessPopup(true);
      setTimeout(closeSuccessPopup, 3000);
    } catch (err) {
      setError(
        `خطأ: ${
          err.message || "فشل في حذف البيانات. يرجى المحاولة مرة أخرى لاحقًا."
        }`
      );
    } finally {
      setIsDeleteModalOpen(false);
      setItemToDelete(null);
    }
  };

  return (
    <div className="provinces-container">
      <div className="header-section">
        <h2>المندوبين</h2>
        <button className="add-button" onClick={() => handleOpenModal(false)}>
          اضافة مندوب +
        </button>
      </div>

      {loading ? (
        <p>Loading...</p>
      ) : (
        <table className="provinces-table">
          <thead>
            <tr>
              <th>ID</th>
              <th>المحافظة</th>
        
              <th>الاسم بالكامل</th>
        
              <th>رقم الهاتف</th>
            </tr>
          </thead>
          <tbody>
            {apiData.data && apiData.data.length > 0 ? (
              apiData.data.map((delegate, index) => (
                <tr
                  key={delegate.id}
                  onClick={() => handleOpenModal(true, delegate)}
                >
                  <td>{index + 1}</td>
                  <td>{delegate.governorate.name}</td>

                  <td>{delegate.fullName}</td>
      
                  <td>{delegate.phoneNumber}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="7">لا توجد بيانات متاحة</td>
              </tr>
            )}
          </tbody>
        </table>
      )}

      {isModalOpen && (
        <div className="modal-overlay">
          <div className="modal-content">
            <div className="modal-header">
              <h3>{isEditMode ? "تعديل المندوب" : "مندوب جديد"}</h3>
              <FontAwesomeIcon
                icon={faTimes}
                className="modal-close"
                onClick={handleCloseModal}
              />
            </div>
            <hr />
            <div className="modal-body">
              <div className="form-group">
                <label>المحافظة</label>
                <select
                  name="governorateId"
                  value={newDelegate.governorateId}
                  onChange={handleInputChange}
                  aria-label="اختيار المحافظة"
                  style={{ width: "95%" }}
                >
                  <option value="">اختر محافظة</option>
                  {governorateOptions.data.map((governorate) => (
                    <option key={governorate.id} value={governorate.id}>
                      {governorate.name}
                    </option>
                  ))}
                </select>
              </div>
  
              <div className="form-group">
                <label>الاسم بالكامل</label>
                <input
                  type="text"
                  name="fullName"
                  value={newDelegate.fullName}
                  onChange={handleInputChange}
                  placeholder="الاسم بالكامل"
                  aria-label="الاسم بالكامل"
                />
              </div>
      
              <div className="form-group">
                <label>رقم الهاتف</label>
                <input
                  type="text"
                  name="phoneNumber"
                  value={newDelegate.phoneNumber}
                  onChange={handleInputChange}
                  placeholder="رقم الهاتف"
                  aria-label="رقم الهاتف"
                />
              </div>
            </div>
            <div className="modal-footer">
              <button
                className="action-button"
                onClick={handleSave}
                disabled={saving}
                aria-label={isEditMode ? "تحديث المندوب" : "حفظ المندوب"}
              >
                <FontAwesomeIcon icon={faSave} /> {isEditMode ? "تحديث" : "حفظ"}
              </button>
              {isEditMode && (
                <button
                  className="action-button"
                  onClick={() => handleDeleteConfirmation(newDelegate)}
                >
                  <FontAwesomeIcon icon={faTrash} /> حذف
                </button>
              )}
            </div>
          </div>
        </div>
      )}

      {showSuccessPopup && (
        <div className="modal-overlay Success-modal">
          <div className="modal-content">
            <div className="modal-body">
              <p>{successMessage}</p>
            </div>
            <div className="modal-footer">
              <button className="action-button" onClick={closeErrorPopup}>
                إغلاق
              </button>
            </div>
          </div>
        </div>
      )}

      {error && (
        <div className="modal-overlay Success-modal">
          <div className="modal-content">
            <div className="modal-body">
              <p>{error}</p>
            </div>
            <div className="modal-footer">
              <button className="action-button" onClick={closeErrorPopup}>
                إغلاق
              </button>
            </div>
          </div>
        </div>
      )}

      {isDeleteModalOpen && (
        <div className="modal-overlay">
          <div className="modal-content">
            <div className="modal-header">
              <h3>تأكيد الحذف</h3>
              <FontAwesomeIcon
                icon={faTimes}
                className="modal-close"
                onClick={() => setIsDeleteModalOpen(false)}
              />
            </div>
            <hr />
            <div className="modal-body">
              <p>هل أنت متأكد أنك تريد حذف هذا المندوب؟</p>
            </div>
            <div className="modal-footer">
              <button className="action-button" onClick={handleDelete}>
                تأكيد الحذف
              </button>
              <button
                className="action-button"
                onClick={() => setIsDeleteModalOpen(false)}
              >
                إلغاء
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default DelegateComponent;
